<template>
  <div class="container-fluid reduction-setting">
    <h1 class="mt-10 reduction-setting__title">{{ getTitlePage }}</h1>
    
    <div class="mt-12 reduction-setting__description">{{ step === 0 ? $t("register_reduction.description_basic_information") : $t("edit_reduction.description_basic_information")}}  </div>
    <template v-if="isIntegrate.data && step === 0 && !isRedutionIntegrate && !isStateEdit">
      <div class="infor-reduction">
        <img class="icon-infor" src="@/assets/images/dialog/notification.svg" alt="">
        {{ $t('icp_target_integration.description_check_integrate') }}
      </div>
      <common-button
          class="submit-register-btn" 
          :label="$t('icp_target_integration.button_integarate')"
          v-ripple="false"
          @action="$router.push({ name: 'SettingTarget' })"
      />
    </template>
    <div class="mt-12 reduction-content" ref="reductionContent" :class="step > 0 && 'has-left-site'">
      
      <SelectTab :step="step" />
      <!-- step 1 -->
      <ScopeProductSelect v-if="step === 0 && key > 0" class="mt-12"
        :dataSetting="dataSetting"
        @updateCanNextStep="updateCanNextStep"
        :isDisableInput="isRedutionIntegrate"
        @getDataList="getDataList"
        @getDataLast="getDataLast"
        @updateStartYear="updateStartYear"
        @updateEndYear="updateEndYear"
      />

      <!-- step 2 -->
      <CompareEmission
        :dataSetting="dataSetting"
        v-if="step === 1"
        class="mt-12"
        :typeInput="typeInput"
        :isDisableInput="isRedutionIntegrate"
        @updateCanNextStep="updateCanNextStep1"
        @updateYearCompare="updateYearCompare"
        @updateYearCompareCustome="updateYearCompareCustome"
        @handleAddItemCompare="handleAddItemCompare"
        @updateDataTitle="updateDataTitle"
        @updateCarbonCompareCustome="updateCarbonCompareCustome"
        @updateTypeInput="handleUpdateTypeInput"
        @updateIsInputCarbon ="updateIsInputCarbon"
      />
      <!-- Step 3 -->
      <ICPInputStep
        @updateCanNextStep="updateCanNextStep2"
        @getIcpSettingValue="updateIcpSettingValue"
        :dataSetting="dataSetting"
        v-if="step === 2"
      />
    </div>

    <div
      class="scroll-element"
      ref="scrollElement"
    >
      <!--phần tử muốn theo dõi -->
    </div>

    <div class="reduction-bottom" :class="{'fixed-button-bottom': isFixedButton}">
      <div v-if="step > 0" class="reduction-bottom-left"></div>
      <div class="reduction-content action reduction-content-sp" :class="step > 0 && 'both-btn reduction-bottom-right'">
        <CommonButton v-if="step > 0" @action="handlePrevStep" :label="labelBtnReturn" class="no-hover-sp">
          <img src="@/assets/images/dialog/return.svg" class="icon-back mr-2" />
          <img src="@/assets/images/dialog/return_active.svg" class="icon-back active mr-2" />
        </CommonButton>
        <CommonButton @action="handleNextStep" :isDisable="!isDisableNextStep" type="colored" :label="renderLabel" />
      </div>
    </div>
    <notification-popup :dialog="showSuccess" :message="messagePopupSuccess" @submit="handleSuccess" />
  </div>
</template>

<script>
//js
import { mapActions, mapState, mapGetters } from 'vuex';
import { ROUTES } from '@/router/constants';

//DOM
import SelectTab from '@/components/products/reductions/SelectTab.vue';
import ScopeProductSelect from '@/components/products/reductions/ScopeProductSelect.vue';
import CompareEmission from '@/components/products/reductions/CompareEmission.vue';
import ICPInputStep from '@/components/products/reductions/ICPInputStep.vue'
import CommonButton from '@/components/utils/button.vue';
import { createReductionTargetApi, updateReductionTargetApi, checkintegrateTarget } from '@/api/reduction-target';
import notificationPopup from '@/components/dialogs/notification-popup.vue';
import { getReductionTargetDetailApi } from '@/api/reduction-target';
import { handleGetScopeCategories } from "@/utils/getScopeCategories";
import { compareObjects } from '@/utils/compare'
import { scopeCategoriesData, oldmethod, newPcafMethod } from '@/constants/export-report';
import { calcRangeYearByStartAndEndData } from '@/constants/reduction'
import { TEXT_NULL } from "@/constants/dashboard";
import { PCAF_CATEGORY_TYPE_METHOD_1, PCAF_CATEGORY_TYPE_METHOD_2 } from '@/constants/pcaf';
import { getDataTypeApi } from '@/api/pcaf'
import { handleUpdateRowNumpattern, setDataTypeForPcafMethod } from '@/utils/pcaf'
import { formatNumberBySetting } from '@/concerns/newRegisterData/wijmo.helper';
export default {
  name: 'SettingReduction',
  components: { SelectTab, ScopeProductSelect, CommonButton, CompareEmission, ICPInputStep, notificationPopup },
  props: {
    isRedutionIntegrate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      step: 0,
      isNextStep: false,
      isNextStep1: false,
      isNextStep2: false,
      dataSetting: {},
      dataYears: [],
      dataList: [],
      showSuccess: false,
      listYearCompare: [],
      organizationList: [
        { id: 1, value: this.$t('b_list_all.pulldown_single_company')},
        { id: 2, value: this.$t('b_list_all.pulldown_subsidiaries_company') },
        { id: 3, value: this.$t('b_list_all.pulldown_associated_company') },
        { id: 4, value: this.$t('b_list_all.pulldown_associated_company_other') }
      ],
      oldItemCompare: null,
      isFixedButton: true,
      heightButton: 156,
      isIntegrate: false,
      typeInput: 0,
      key: 0,
      observer: null,
      allDataType: {}
    };
  },
  computed: {
    ...mapState('newRegisterData', ['isNextTab']),
    ...mapState("userData", ["isSettingPcaf", "planType"]),
    ...mapGetters({
      listReductionTargetIntegration: 'reductionTarget/getReductionTargetIntegration',
    }),
    isStateEdit() {
      const idRecord = this.$route.query?.id;
      if(idRecord) return true;
      return false
    },
    renderLabel() {
      if (this.step === 0) {
        return this.$t("register_reduction.button_next");
      }
      if (this.step === 1) {
        return this.$t("register_reduction.button_next");
      }
      if (this.step === 2) {
        return this.$route.query?.id ? this.$t("register_data.button_keep") : this.$t("register_reduction.button_register");
      }
      return ""
    },
    isDisableNextStep() {
      if (this.step === 0) {
        return this.isNextStep;
      }
      if (this.step === 1) {
        return this.isNextStep1;
      }
      if (this.step === 2) {
        return this.isNextStep2;
      }
    },
    isValidDuration() {
      const keyType = this.dataSetting.type_setting ? 'target_emissions_auto': 'target_emissions';
      const format = this.listYearCompare.map(item => item[keyType] )
      format.forEach((record) => {
        Object.keys(record).forEach((key) => {
          if (record[key] === "" || record[key] === undefined) {
            return false
          }
        });
      });
      return true;
    },
    labelBtnReturn() {
      return this.$t("register_reduction.button_return");
    },
    messagePopupSuccess() {
      return this.$t("register_reduction.description_message_popup_success");
    },
    getTitlePage() {
      let title = ''
      if(this.isStateEdit) {
        if(this.isRedutionIntegrate) {
          title = this.$t('icp_target_integration.title_page_reduction_taget')
        } else {
          title = this.$t("edit_reduction.title_title_page")
        }
      } else {
        title = this.$t('register_reduction.title_title_page')
      }
      return title
    }
  },
  async mounted() {
    if (this.isSettingPcaf) {
      await this.handleGetDataType()
    }
    this.isIntegrate = await checkintegrateTarget()
    await this.handleGetDataReductionTarget();
    this.handleUpdateBreadCrum();
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.showSuccess = false;
      }
    });
    const targetNode = this.$refs.reductionContent;
    if (targetNode) {
      this.observer = new MutationObserver(() => {
        // The element has been update, call handleScroll
        this.handleScroll();
      });

      const config = { childList: true, subtree: true };
      this.observer.observe(targetNode, config);
    }
    window.addEventListener('resize', this.setValueHeightButton);
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setValueHeightButton);
    window.removeEventListener("scroll", this.handleScroll);
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  watch: {
    step: function() {
      this.setValueHeightButton();
      this.isFixedButton = false;
    }
  },
  methods: {
    ...mapActions("newRegisterData", ["updateIsNextTab"]),
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('reductionTarget', ['setLastedReductionTarget']),
    setValueHeightButton() {
      if(this.step > 0) {
        this.heightButton = window.innerWidth >= 768 ? 156 : 218;
      } else {
        this.heightButton = 156;
      }
    },

    handleScroll() {
      const scrollElementTop = this.$refs.scrollElement ? this.$refs?.scrollElement?.offsetTop : ''; // Vị trí của phần tử
      const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại

      if (scrollPosition > (scrollElementTop + this.heightButton)) {
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
    },
    updateYearCompareCustome(index, newVal, type) {
      const keyType = type ? 'target_emissions_auto' : 'target_emissions';
      const item = this.listYearCompare[index]

      if(typeof item[keyType] !== "object") {
        item[keyType] = JSON.parse(item[keyType])
      }
      if(item.typeInput) {
        newVal = this.getRangeYearAndcalcRangeYearByStartAndEnd(item, 'target_emissions_auto', newVal);
      }

      this.listYearCompare[index][keyType] = newVal
      if(this.$route.query?.id) {
        if(index === 0) {
          this.dataSetting.target_year[keyType] = JSON.stringify(newVal)
        } else {
          this.dataSetting.target_compare_year[index - 1][keyType] = JSON.stringify(newVal)
        }
      }
      // Always use key: target_emissions related to filterYearInObjectReturnArr in CompareEmission
      if(index) {
        if (!this.dataSetting.hasOwnProperty("target_compare_year")) {
          this.dataSetting.target_compare_year = [];
        }
        this.dataSetting.target_compare_year[index - 1] = { 
          ...this.dataSetting.target_compare_year[index - 1],
          ...this.listYearCompare[index],
          target_emissions: JSON.stringify(this.listYearCompare[index][keyType]),
        };
      } else {
        this.dataSetting.target_year = { 
          ...this.dataSetting.target_year,
          ...this.listYearCompare[index],
          target_emissions: JSON.stringify(this.listYearCompare[index][keyType]),
        };
      }
      this.getLastedDataValues();
    },
    getRangeYearAndcalcRangeYearByStartAndEnd(item, key, newVal) {
      const startYearTagetVal = item[key][item.start_year]
      const endYearTagetVal = item[key][item.end_year]
      const rangeYear = item.end_year - item.start_year
      const isCalcEmissionsByRangeYearValue = rangeYear > 1
      if(isCalcEmissionsByRangeYearValue && endYearTagetVal && startYearTagetVal) { // this.listYearCompare[index].typeInput === 1
        const startYear = item.start_year
        const endYear = item.end_year
        const data = { item, startYearTagetVal, endYearTagetVal, rangeYear, startYear, endYear }
        return this.calcRangeYearByStartAndEnd(data, key)
      }
      return newVal
    },
    updateCarbonCompareCustome(index, newVal, type) {
      const keyType = type ? 'carbons_auto' : 'carbons';
      const keyTypeEmissions = type ? 'target_emissions_auto' : 'target_emissions';
      const item2 = this.listYearCompare[index]
      if(typeof item2[keyType] !== "object") {
        item2[keyType] = JSON.parse(item2[keyType])
      }

      if(item2.typeInput) {
        newVal = this.getRangeYearAndcalcRangeYearByStartAndEnd(item2, 'carbons_auto', newVal);
      }
      
      // No need setting carbon for compare item
      this.listYearCompare[index][keyType] = newVal

      // always set target_year for carbons
      this.dataSetting.target_year = { 
        ...this.dataSetting.target_year,
        ...this.listYearCompare[index],
        target_offsets: JSON.stringify(this.listYearCompare[index][keyType]),
        type_setting: item2.typeInput,
        target_emissions: JSON.stringify(this.listYearCompare[index][keyTypeEmissions]),
      };
      this.getLastedDataValues();
    },
    updateIsInputCarbon(value) {
      this.dataSetting = {
        ...this.dataSetting,
        active_offset: value,
      }
      // Handle case back data
      this.dataSetting.target_year = {
        ... this.dataSetting.target_year,
        active_offset: value,
      }
    },
    handleAddItemCompare(newItem) {
      this.listYearCompare.push(newItem)
    },
    updateDataTitle(index, newData) {
      this.listYearCompare[index].title = newData
      if(index && this.dataSetting.hasOwnProperty('target_compare_year')) {
        this.dataSetting.target_compare_year[index -1] = {
          ...this.dataSetting.target_compare_year[index - 1],
          title: newData
        }
      }
    },
    updateIcpSettingValue(value) {
      this.dataSetting = {
        ...this.dataSetting,
        setting_icp: value
      }
    },
    updateStartYear(startYear){
      this.dataSetting = {
        ...this.dataSetting,
        startAt: startYear,
      }
    },
    updateEndYear(endYear) {
      this.dataSetting = {
        ...this.dataSetting,
        endAt: endYear
      }
    },
    getScopeCategories(pattern_ids,scopeCategoriesData){
      return handleGetScopeCategories(pattern_ids, scopeCategoriesData, this.allDataType);
    },

    getInfoBranch(branch,fieldName){
      const information = branch.map(item => item[fieldName] || TEXT_NULL);
      return information;
    },
    async handleGetDataReductionTarget() {
      const id = parseInt(this.$route.query?.id);
      if(id) {
        try {
          const indexScope3Category15 = scopeCategoriesData.findIndex(item => item.categories === 15)
          let dataScope = scopeCategoriesData
          let pcafMethod = newPcafMethod
          if (this.planType?.is_pcaf && !this.allDataType.length) {
            pcafMethod = setDataTypeForPcafMethod(newPcafMethod, this.allDataType)
          }
          dataScope[indexScope3Category15].methods = this.planType?.is_pcaf ? pcafMethod : oldmethod;
          const reductionTargetDetailApi = await getReductionTargetDetailApi(id);
          this.oldItemCompare = [...reductionTargetDetailApi.data?.target_compare_year];
          this.dataSetting = {
            ...reductionTargetDetailApi.data,
            "startAt": reductionTargetDetailApi.data.start_year || null,
            "endAt": reductionTargetDetailApi.data.end_year || null,
            "listScopeCategories": reductionTargetDetailApi.data.pattern_ids ? this.getScopeCategories(reductionTargetDetailApi.data.pattern_ids,dataScope).map(item => item.text) : [],
            "organizational": reductionTargetDetailApi.data.branches?.organizational_division ? (this.organizationList.filter(record => (this.getInfoBranch(reductionTargetDetailApi.data.branches?.organizational_division,"organizational_division")).includes(record.id))).map(ele => ele.value) : [],
            "companies": reductionTargetDetailApi.data.branches.company_name ? this.getInfoBranch(reductionTargetDetailApi.data.branches.company_name,"company_name") : [],
            "business": reductionTargetDetailApi.data.branches.business_name_ids ? this.getInfoBranch(reductionTargetDetailApi.data.branches.business_name_ids,"business_name") : [],
            "countries": reductionTargetDetailApi.data.branches.country_ids ? this.getInfoBranch(reductionTargetDetailApi.data.branches.country_ids,"country") : [],
            "layer3": reductionTargetDetailApi.data.branches.layer_3_ids ? this.getInfoBranch(reductionTargetDetailApi.data.branches.layer_3_ids,"layer_3") : [],
            "layer4": reductionTargetDetailApi.data.branches.layer_4_ids ? this.getInfoBranch(reductionTargetDetailApi.data.branches.layer_4_ids,"layer_4") : [],
            "layer5": reductionTargetDetailApi.data.branches.layer_5_ids ? this.getInfoBranch(reductionTargetDetailApi.data.branches.layer_5_ids,"layer_5") : [],
            "layer6": reductionTargetDetailApi.data.branches.layer_6_ids ? this.getInfoBranch(reductionTargetDetailApi.data.branches.layer_6_ids,"layer_6") : [],
          }
          this.key ++
        } catch (error) {
          return this.$router.push('/');
        }
      } else {
        this.key ++
      }
    },
    async handleGetDataType() {
      try {
        const responseDataType = await getDataTypeApi()
        this.allDataType = responseDataType.data
      } catch (error) {
        console.warn(error);
      }
    },
    handleSuccess() {
      this.$router.push({ path: ROUTES.PRODUCT_REDUCTION });
    },
    handleUpdateBreadCrum() {
      if(this.isRedutionIntegrate) {
        this.breadcrumb = [
          {
            text: this.$t('list_reduction.hyperlink_home'),
            disabled: false,
            href: ROUTES.HOME_DASHBOARD,
          },
          {
            text: this.$t('reduction.title_title_page'),
            disabled: false,
            href: ROUTES.PRODUCT_REDUCTION,
          },
          {
            text: this.$t('icp_target_integration.title_page_integrate'),
            disabled: false,
            href: ROUTES.SETTING_TARGET,
          },
          {
            text: this.$t('icp_target_integration.title_page_reduction_taget'),
            disabled: true,
            href: ROUTES.LIST_PRODUCTS,
          },
        ]
      } else if (this.$route.path == ROUTES.PRODUCT_REDUCTION_SETTING) {
        this.breadcrumb = [
          {
            text: this.$t("register_reduction.hyperlink_home"),
            disabled: false,
            href: ROUTES.DASHBOARD,
          },
          {
            text: this.$t("register_reduction.hyperlink_reduction_target"),
            disabled: false,
            href: ROUTES.PRODUCT_REDUCTION,
          },
          {
            text: (this.$route.query?.id) ? `${this.$t("edit_reduction.label_edit_reduction")} ${this.dataSetting.title}` : this.$t("register_reduction.label_sign_up"),
            disabled: true,
            href: '',
          },
        ];
      } else if (this.$route.path == ROUTES.CREATE_REDUCTION_TARGET) {
        this.breadcrumb = [
          {
            text: this.$t("register_reduction.hyperlink_home"),
            disabled: false,
            href: ROUTES.DASHBOARD,
          },
          {
            text: this.$t("register_reduction.hyperlink_setting"),
            disabled: false,
            href: ROUTES.SETTING,
          },
          {
            text: this.$t("register_reduction.hyperlink_reduction_target_manage"),
            disabled: false,
            href: ROUTES.REDUCTION_TARGET_LIST_MANAGEMENT,
          },
          {
            text: (this.$route.query?.id) ? `${this.$t("edit_reduction.label_edit_reduction")} ${this.dataSetting.title}` : this.$t("register_reduction.label_sign_up"),
            disabled: true,
            href: '',
          },
        ];
      }
      this.updateBreadCrumb(this.breadcrumb);
    },
     redirectToReductionList() {
      //TODO: handle redirect to screen list reduction target
     },
     covertValueYear(data) {
      const obj = data;
      for (const key in obj) {
        obj[key] = obj[key];
      }
      return obj;
     },
     async handleNextStep() {
      if (this.step < 2) {
        this.step += 1;
        //TODO: save data step 1
        this.updateIsNextTab(false)
      }
      else{
        this.updateIsNextTab(true)
        // try {
          const keyTypeSetting = this.dataSetting.type_setting ? 'target_emissions_auto' : 'target_emissions';
          const keyTypeCarbons = this.dataSetting.type_setting ? 'carbons_auto' : 'carbons';
          const targetEmissions = this.listYearCompare.length > 0 && this.covertValueYear(this.listYearCompare[0][keyTypeSetting])
          const carbonOffset = this.listYearCompare.length > 0 && this.covertValueYear(this.listYearCompare[0][keyTypeCarbons])
          const value_icps = typeof this.dataSetting.setting_icp?.value_icps === 'object' ? this.covertValueYear(this.dataSetting.setting_icp?.value_icps) : null;
          const settingIcp = { ...this.dataSetting.setting_icp, value_icps: value_icps}
          this.listYearCompare.shift();
          this.listYearCompare.forEach(function(ele){ delete ele.index });

          const modifiedData = this.listYearCompare.map(obj => {
            const clonedObj = JSON.parse(JSON.stringify(obj));
            const keyTypeItem = clonedObj.typeInput ? 'target_emissions_auto': 'target_emissions';
            for (const key in clonedObj[keyTypeItem]) {
              clonedObj.target_emissions[key] = (clonedObj[keyTypeItem][key]);
            }
            clonedObj.type_setting = clonedObj.typeInput ? true : false
            delete clonedObj.typeInput
            delete clonedObj.target_emissions_auto
            delete clonedObj.carbons
            delete clonedObj.carbons_auto
            delete clonedObj.value
            delete clonedObj.years
            return clonedObj;
          });

          let patterIds  = this.dataSetting.listScopeCategories.map(item => {
            const splitItem = item.split('_');
            let data = scopeCategoriesData.find(method => method.text === splitItem[0]);

            if(data.categories === 15 && this.isSettingPcaf){
              const listDataTypeSelect = []
              const methodSelect = data.methods.find(item => item.text === splitItem[1])
              const nameTypeSelect = splitItem.slice(1).join('_')
              const detailType = methodSelect.methods.find(method => method.text_select === nameTypeSelect)
              //get type_classify from PCAF_CATEGORY_TYPE_METHOD_1 or 2
              const typeClassifyObj = detailType?.row_num_pattern === 47 ? PCAF_CATEGORY_TYPE_METHOD_1 : PCAF_CATEGORY_TYPE_METHOD_2
              const typeDetail = typeClassifyObj.find(typeItem => typeItem.name === splitItem[2])
              if(detailType?.data_type) {
                listDataTypeSelect.push(detailType.data_type)
              }
              return {
                row_num_pattern: detailType?.row_num_pattern,
                type_data: listDataTypeSelect,
                type_classify: typeDetail?.id 
              }
            } else {
              for(let i = 1; i < splitItem.length; i++) {
                data = data?.methods.find(method => method.text === splitItem[i]);
              }
              return data?.row_num_pattern;
            }
          });

          const payload = {
            title: this.dataSetting?.title?.substr(0, 255),
            note: this.dataSetting?.note?.substr(0, 255),
            organizational_division_ids: this.dataSetting.branchIds.organizational,
            company_name_ids: this.dataSetting.branchIds.companies,
            start_year: this.dataSetting.startAt,
            end_year: this.dataSetting.endAt,
            pattern_ids: handleUpdateRowNumpattern(patterIds),
            target_emissions: targetEmissions,
            target_compares: modifiedData,
            type_setting: this.dataSetting.type_setting ? true : false,
            active_offset: this.dataSetting?.active_offset ?? false,
            target_offsets: carbonOffset,
            setting_icp: settingIcp,
            business_name_ids: this.dataSetting.branchIds.business,
            country_ids: this.dataSetting.branchIds.countries,
            layer_3_ids: this.dataSetting.branchIds.layer3,
            layer_4_ids: this.dataSetting.branchIds.layer4,
            layer_5_ids: this.dataSetting.branchIds.layer5,
            layer_6_ids: this.dataSetting.branchIds.layer6,
          }

          if(this.$route.query?.id) { //handle update data
            payload.target_compares = this.getDataCompareUpdate(modifiedData)
            try {
              const target = await updateReductionTargetApi(this.$route.query?.id, payload)
              if (target && target.data && target.data.id !== undefined) {
                this.setLastedReductionTarget(target.data.id);
              }
              this.$router.push({ path: '/reduction', query: { isUpdate: true }})
            } catch (error) {
              console.warn(error);
            }
            return
          }
          const result = await createReductionTargetApi(payload)
          if (result && result.data && result.data.id !== undefined) {
              this.setLastedReductionTarget(result.data.id);
          }
          this.showSuccess = true;
        // } catch (err) {
        //   console.log("[CREATE ERR:]", { ...err });
        // }
      }
    },
    getDataCompareUpdate(dataCompare) {
      let dataUpdateReal = []
      const dataAdd = dataCompare?.filter(item => !item.id) || []
      const dataUpdate = dataCompare?.filter(item => item.id) || []
      dataUpdate.forEach(itemUpdate => {
        const oldData = this.oldItemCompare.find(item => item.id === itemUpdate.id)
        const oldDataCompare = {
          id: oldData.id,
          end_year: oldData.end_year?.toString(),
          start_year: oldData.start_year?.toString(),
          title: oldData.title,
          target_emissions: JSON.parse(oldData.target_emissions),
        }

        if(!compareObjects(oldDataCompare, itemUpdate)) {
          dataUpdateReal.push(itemUpdate)
        }
      })


      let dataDelete = []
      this.oldItemCompare?.forEach(itemCompare => {
        const indexDelete = dataUpdate?.findIndex(itemUpdate => itemUpdate?.id === itemCompare?.id)
        if(indexDelete < 0) {
          dataDelete.push(itemCompare.id)
        }
      })
      return { add: dataAdd, update: dataUpdateReal, delete: dataDelete}
    },
    handlePrevStep() {
      this.step = this.step - 1;
    },
    updateCanNextStep2(newVal) {
      this.isNextStep2 = newVal;
    },
    updateCanNextStep1(newVal, dataYears) {
      this.isNextStep1 = newVal;
      this.dataYears = dataYears;
    },
    updateYearCompare(dataYearCompare) {
      this.listYearCompare = dataYearCompare;
    },
    updateCanNextStep(newVal) {
      this.isNextStep = newVal;
    },
    getDataList(data) {
      this.dataList = data;
    },
    getNote(data) {
      this.dataSetting = {
        ...this.dataSetting,
        note: data
      }
    },
    getTitle(data) {
      this.dataSetting = {
        ...this.dataSetting,
        title: data
      }
    },
    getDataLast(data) {
      this.dataSetting = {
        ...this.dataSetting,
        ...data
      }
    },
    getLastedDataValues() {
      this.dataSetting = {... this.dataSetting, 'has_data_back': true}
    },
    handleUpdateTypeInput(newVal, index, isInputCarbon) {
      // this.typeInput = newVal
      if(this.listYearCompare[index]) {
        this.listYearCompare[index].typeInput = newVal
      }
      if(newVal) {
        this.handleUpdateDataEmissionByType('target_emissions_auto')
        if(isInputCarbon) {
          this.handleUpdateDataEmissionByType('carbons_auto');
        }
      }
      if(index === 0) {
        this.dataSetting.type_setting = newVal
      }
      const newkey = index ? 'target_compare_year': 'target_year';
      if(index) {
        this.dataSetting[newkey][index-1] = { ...this.dataSetting[newkey][index-1], type_setting: newVal };
      } else {
        this.dataSetting[newkey] = { ...this.dataSetting.target_year, type_setting: newVal };
      }

      this.getLastedDataValues();
    },
    handleUpdateDataEmissionByType(key) {
      this.listYearCompare.forEach((item, index) => {
        const startYearTagetVal = item[key][item.start_year];
        const endYearTagetVal = item[key][item.end_year]
        const rangeYear = item.end_year - item.start_year
        const isCalcEmissionsByRangeYearValue = rangeYear > 1

        if(isCalcEmissionsByRangeYearValue && endYearTagetVal && startYearTagetVal) {
          const startYear = item.start_year
          const endYear = item.end_year
          const data = { item, startYearTagetVal, endYearTagetVal, rangeYear, startYear, endYear }
          this.calcRangeYearByStartAndEnd(data, key)
        }
      })
    },
    calcRangeYearByStartAndEnd(data, key) {
      const { item } = data
      const newEmission = calcRangeYearByStartAndEndData(data, key)
      // this.listYearCompare[item.index].target_emissions_auto = JSON.stringify(newEmission)
      return newEmission
    },
  },

  beforeDestroy() {
    this.$store.dispatch('reductionTarget/setTargetSelect', null);
  }
};
</script>

<style lang="scss" scoped>

.infor-reduction {
  display: flex; 
  align-items: center; 
  margin-top: 16px; margin-bottom: 8px; 
  font-family: Noto Sans JP;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.42px;
  color: $monoBlack;

  .icon-infor {
    width: 18px; 
    height: 18px; 
    margin-right: 9px;
  }
}
.reduction-setting {
  padding: 0;
  &__title {

    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 1.1px;
    color: $goldMid;
  }
  &__description {
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: $monoBlack;
  }
}

.fixed-button-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
  z-index: 99;
}
.reduction-content {
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  margin-bottom: 80px;
  &.action {
    display: flex;
    padding: 16px 20px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    background: $bgMid;
    box-shadow: 0px -1.2400000095367432px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
      0px -3.130000114440918px 2.866743326187134px 0px rgba(160, 181, 186, 0.17);
    .common-btn {
      width: 100%;
    }
    &.both-btn {
      .common-btn {
        width: calc((100% - 16px) / 2);
        .v-btn__content {
          .icon-back {
            display: block;
            &.active {
              display: none;
            }
          }
        }
        &:hover {
          .v-btn__content {
            .icon-back {
              display: none;
              &.active {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.reduction-bottom {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -80px;
  background: linear-gradient(20deg, #f1e8e6 0%, #eff5f5 25.82%, #eff5f5 0.93%) !important;
}

@include desktop {
  .reduction-setting {
    padding: 0 40px;
  }
  .reduction-content {
    &.has-left-site {
      max-width: 848px;
    }
    &.action {
      height: 72px;
    }
  }

  .reduction-bottom {
    // max-width: 848px;
    // width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 48;
    height: 72px;
    margin-bottom: -80px;
    background: linear-gradient(20deg, #f1e8e6 0%, #eff5f5 25.82%, #eff5f5 0.93%) !important;

    .reduction-bottom-left {
      display: block;
      width: 289px;
      min-width: 289px;
    }
    
    &.fixed-button-bottom {
      position: fixed;
      bottom: 0;
      left: 236px;
      margin-bottom: 0 !important;
    }
  }
}

@media (max-width: $tablet) {
  .reduction-content-sp {
    flex-direction: column;

    .common-btn {
      width: 100% !important;
    }
  }
}
</style>
